
import { Component, Vue } from 'vue-property-decorator';

// Config
import { RouteNames } from "@/config/router.config";
import { track, TrackingEventType } from "@/config/tracking.config";

// Components
import PageHeader from "@/components/page-header.component.vue";
import ProductTile from "@/components/product-tile.component.vue";

// Models
import CollectionModel from "../models/collection.model";

// Resources
import { getCollectionById } from "@/resources/collections.resource.ts";

@Component({
  components: {
    PageHeader,
    ProductTile
  }
})
export default class CollectionView extends Vue {
  collection: CollectionModel | null = null;

  protected created() {
    this.loadCollection(this.$route.params.collectionId);
  }

  loadCollection(collectionId: string) {
    getCollectionById(collectionId)
      .then((collection: CollectionModel) => {
        track(TrackingEventType.ViewContent, {
          content_category: RouteNames.Collection,
          content_ids: [
            this.$route.params.collectionId
          ],
          content_name: collection.localizedTitle,
          content_type: 'product_group',
         });

        this.collection = collection;
      });
  }
}
