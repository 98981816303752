
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class PageHeader extends Vue {
  @Prop({ type: String, required: true })
  title!: string;

  @Prop({ type: String, default: '' })
  subtitle!: string;
}
